import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["label", "input", "errorLabel"]

  connect() {
    console.log('form field');
    // document.addEventListener('click', this.handleClickOutside.bind(this));
  }

  handleFocus(e) {
    const target = e.target;
    if (this.inputTarget.contains(target) && this.inputTarget.classList.contains("form-input--error")) {
      this.inputTarget.classList.remove("form-input--error")
      this.inputTarget.classList.add("form-input")
    }

    if (this.hasLabelTarget && this.labelTarget.classList.contains("form-input--error")) {
      this.labelTarget.classList.remove("form-input--error")
      this.labelTarget.classList.add("form-label")
    }

    if (this.hasErrorLabelTarget) {
      this.errorLabelTarget.remove()
    }
  }
}

