import { Controller } from "@hotwired/stimulus"
import {enter, leave} from 'el-transition'; 

export default class extends Controller {
  static targets = ["menu", "button"]

  handleClickOutside(event) {
    const hidden = this.menuTarget.classList.contains('hidden')
    if (hidden) {
      return true
    }

    const menuClicked = this.menuTarget.contains(event.target)
    const buttonClicked = this.buttonTarget.contains(event.target)

    if (!menuClicked && !buttonClicked && !hidden ) {
        leave(this.menuTarget)
    }
  }

  connect() {
    console.log('noix');
  }

  disconnect() {
  }

  open() {
    enter(this.menuTarget)
  }

  close() {
    leave(this.menuTarget)
  }
}

